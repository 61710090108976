const heroSwiper = new Swiper('.top-hero-swiper', {
  direction: 'vertical',
  effect: 'creative',
  slidesPerView: 1,
  loop: true,
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  creativeEffect: {
    prev: {
      translate: [0, 0, 0],
    },
    next: {
      translate: [0, '-100%', -100],
    },
  },
  draggable: false,
  allowTouchMove: false,
});

const pickupSwiper = new Swiper('.top-pickup-swiper', {
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.top-pickup-next',
    prevEl: '.top-pickup-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'progressbar',
  },
});

const swiperSlides = document.getElementsByClassName('swiper-slide');
const mediaQuery = window.matchMedia('(min-width: 1024px)');
let deptSwiperAm;
let deptSwiperPm;
let deptSwiperEv;

window.addEventListener(
  'load',
  () => {
    if (!mediaQuery.matches) {
      createSwiper();
    }
  },
  false
);

window.addEventListener(
  'resize',
  () => {
    if (mediaQuery.matches) {
      if (deptSwiperAm) deptSwiperAm.destroy(false, true);
      if (deptSwiperPm) deptSwiperPm.destroy(false, true);
      if (deptSwiperEv) deptSwiperEv.destroy(false, true);
    } else {
      createSwiper();
    }
  },
  false
);

const createSwiper = () => {
  deptSwiperAm = new Swiper('.top-dept-swiper-am', {
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
    },
  });
  deptSwiperPm = new Swiper('.top-dept-swiper-pm', {
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
    },
  });
  deptSwiperEv = new Swiper('.top-dept-swiper-ev', {
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
    },
  });
};
